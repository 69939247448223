import { CloseIcon, MinusIcon, PlusIcon } from 'assets';
import { Container, Copy } from 'components';
import { Link, navigate } from 'gatsby';
import Image from 'gatsby-image';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { useAnalytics } from 'modules/analytics/hooks/useAnalytics';
import { CartProduct, CartThunks, ClientSummary, Currency } from 'modules/cart';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { buttons, cartHome } from 'styles';
import { formatPrice, getProductRoute } from 'utils';

const Cart: React.FC = () => {
  const { products, clientSummary } = useSelector(
    (state: ApplicationState) => state.cart,
  );

  let dryfixExtraCount = 0;
  const dryfixExtraCounters: number[] = [];
  const [dryfixQuantity, setDryfixQouantity] = useState(0);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();

  const { sendCartItemsInteraction, sendContactInteraction } = useAnalytics();
  const { isLoggedIn, user } = useSelector(
    (state: ApplicationState) => state.auth,
  );

  useEffect(() => {
    let totalPrice = 0;

    products.map((item: CartProduct) => {
      totalPrice += item.quantity.quantity * item.price;
    });

    const prices: ClientSummary = {
      totalWithoutTax: +totalPrice.toFixed(2),
      tax: +(totalPrice * 0.25).toFixed(2),
      total: +(totalPrice * 1.25).toFixed(2),
      currency: Currency.CRO,
    };

    dispatch(CartThunks.setClientSummary(prices));
  }, [dispatch, products]);

  useEffect(() => {
    setDryfixQouantity(dryfixExtraCounters.reduce((a, b) => a + b, 0));
  }, [dryfixExtraCounters]);

  useEffect(() => {
    if (dryfixQuantity) dispatch(CartThunks.updateDryfix(dryfixQuantity));
  }, [dryfixQuantity, dispatch]);

  return (
    <Container>
      <Helmet title={'Košarica - Wienerberger Webshop'} />
      <div css={cartHome.root}>
        <Copy
          title={formatMessage({ id: 'cart.home-page.copy.title' })}
          step1Title={formatMessage({ id: 'cart.home-page.copy.step1Title' })}
          step1Description={formatMessage({
            id: 'cart.home-page.copy.step1Description',
          })}
          step2Title={formatMessage({ id: 'cart.home-page.copy.step2Title' })}
          step2Description={formatMessage({
            id: 'cart.home-page.copy.step2Description',
          })}
          step3Title={formatMessage({ id: 'cart.home-page.copy.step3Title' })}
          step3Description={
            <>
              <FormattedMessage id="cart.home-page.copy.step3Description.1" />{' '}
              <strong>
                <FormattedMessage id="cart.home-page.copy.step3Description.2" />
              </strong>
              <FormattedMessage id="cart.home-page.copy.step3Description.3" />
            </>
          }
          containerStyles={cartHome.copy}
        />
        <div css={cartHome.section}>
          <h2 css={cartHome.pageTitle}>
            <FormattedMessage id="shared.cart" />
          </h2>
          {products.length > 0 && (
            <div css={cartHome.infoWrapper}>
              <p css={cartHome.info}>
                <FormattedMessage id="cart.home-page.recommended.price" />
              </p>
            </div>
          )}
        </div>
        {products.length === 0 ? (
          <p css={cartHome.text}>
            <FormattedMessage id="cart.home-page.empty-cart" />
          </p>
        ) : (
          <>
            <div css={cartHome.gridWrapper}>
              <p />
              <p css={cartHome.contentTitle}>
                <FormattedMessage id="cart.home-page.product.name" />
              </p>
              <p css={cartHome.contentTitle}>
                <FormattedMessage id="cart.home-page.product.amount" />
              </p>
              <p css={cartHome.contentTitle}>
                <FormattedMessage id="cart.home-page.product.measure" />
              </p>
              <p css={cartHome.contentTitle}>
                <FormattedMessage id="cart.home-page.product.recommended-price" />
              </p>

              <div css={cartHome.line} />

              {products.map((item) => {
                const minPiecesPerMsq =
                  item.product.sku?.salesRelation.piecesMax ||
                  item.product.sku?.salesRelation.piecesDry ||
                  item.product.sku?.salesRelation.piecesMortar;

                const isDryfixExtraWithBrick =
                  item.title === 'Porotherm Dryfix.extra' && item.price === 0;

                const hiddenButton =
                  isDryfixExtraWithBrick && cartHome.disabledButton;

                const productPricePerM2 =
                  (minPiecesPerMsq || 0) *
                  item.product.sku?.price?.pricePerPiece;
                if (
                  item.title.toLowerCase().includes('profi') &&
                  minPiecesPerMsq !== null &&
                  item.dryFixM2Can
                ) {
                  dryfixExtraCount = Math.ceil(
                    item.quantity.quantity / minPiecesPerMsq / item.dryFixM2Can,
                  );
                  dryfixExtraCounters.push(dryfixExtraCount);
                }
                return (
                  <Fragment key={item.id}>
                    <Link
                      css={cartHome.imageWrapper}
                      to={getProductRoute(item.product.commercial)}
                    >
                      <Image
                        fixed={{
                          src: item.imageSrc,
                          srcSet: '',
                          width: 48,
                          height: 48,
                        }}
                      />
                    </Link>
                    <div>
                      <Link to={getProductRoute(item.product.commercial)}>
                        <p css={cartHome.productTitle}>{item.title}</p>
                      </Link>
                      {Boolean(productPricePerM2) &&
                        !isNaN(productPricePerM2) && (
                          <p css={cartHome.descriptionText}>
                            {formatPrice(productPricePerM2)} €/m2
                          </p>
                        )}
                    </div>

                    <div css={cartHome.priceWrapper}>
                      <button
                        disabled={item.quantity.quantity === 1}
                        css={[cartHome.icon, hiddenButton]}
                        onClick={onReduceClick}
                        data-id={item.id}
                      >
                        <MinusIcon />
                      </button>
                      <p css={cartHome.text}>
                        {isDryfixExtraWithBrick
                          ? dryfixQuantity
                          : item.quantity.quantity}
                      </p>
                      <button
                        disabled={isDryfixExtraWithBrick}
                        css={[cartHome.icon, hiddenButton]}
                        onClick={onAddClick}
                        data-id={item.id}
                      >
                        <PlusIcon />
                      </button>
                    </div>
                    <p css={[cartHome.text, cartHome.quantityResponsive]}>
                      {item.quantity.type}
                    </p>
                    <div css={cartHome.removeWrapper}>
                      <p css={[cartHome.text, cartHome.justifySelfEnd]}>
                        {formatPrice(item.price)} {clientSummary.currency}
                      </p>
                      <button
                        type="button"
                        css={[buttons.container, cartHome.close]}
                        onClick={handleRemove}
                        data-id={item.id}
                      >
                        <CloseIcon />
                      </button>
                    </div>
                    <div css={cartHome.line} />
                  </Fragment>
                );
              })}
            </div>

            <div css={cartHome.totalWrapper}>
              <div css={cartHome.flex}>
                <p css={cartHome.taxesText}>
                  <FormattedMessage id="cart.home-page.product.price-without-vat" />
                </p>
                <p css={[cartHome.taxesText, cartHome.noOpacity]}>
                  {formatPrice(clientSummary.totalWithoutTax)}{' '}
                  {clientSummary.currency}
                </p>
              </div>
              <div css={cartHome.flex}>
                <p css={cartHome.taxesText}>PDV</p>
                <p css={[cartHome.taxesText, cartHome.noOpacity]}>
                  {formatPrice(clientSummary.tax)} {clientSummary.currency}
                </p>
              </div>
              <div css={cartHome.fullLine} />
              <div css={[cartHome.flex, cartHome.taxesPadding]}>
                <p css={[cartHome.taxesText, cartHome.noOpacity]}>
                  <FormattedMessage id="cart.home-page.product.price-with-vat" />
                </p>
                <p
                  css={[
                    cartHome.taxesText,
                    cartHome.noOpacity,
                    cartHome.boldText,
                  ]}
                >
                  {formatPrice(clientSummary.total)} {clientSummary.currency}
                </p>
              </div>
              <div css={cartHome.buttonWrapper}>
                <button css={cartHome.buttonAdd} onClick={onOfferClick}>
                  <FormattedMessage id="cart.data.next-step" />
                </button>
                <button css={cartHome.buttonDelete} onClick={deleteCart}>
                  <FormattedMessage id="cart.data.empty-cart" />
                </button>
              </div>
              <div css={[cartHome.flex, cartHome.infoPadding]}>
                <div css={cartHome.redLine} />
                <p css={cartHome.offerText}>
                  <FormattedMessage id="cart.home-page.product.offer-text" />
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  );

  function deleteCart() {
    dispatch(CartThunks.removeAll());
  }

  function onOfferClick() {
    if (isLoggedIn && user) {
      sendContactInteraction({
        firstName: user.firstName,
        lastName: user.firstName,
        email: user.email || '',
        uid: user.uid,
        isSubscribed: user.subscription,
      }).then(() => {
        sendCartItemsInteraction({
          interactionProducts: products.map((product) => {
            return {
              Name: product.title,
              ItemId: product.id,
            };
          }),
        });
      });
    }

    isLoggedIn ? navigate('/kosarica/podaci') : navigate('/kosarica/prijava');
  }

  function onReduceClick(event: React.MouseEvent<HTMLButtonElement>) {
    const { id } = event.currentTarget.dataset;
    if (!id) return;

    dispatch(CartThunks.removeQuantity(id));
  }

  function onAddClick(event: React.MouseEvent<HTMLButtonElement>) {
    const { id } = event.currentTarget.dataset;
    if (!id) return;

    dispatch(CartThunks.addQuantity(id));
  }

  function handleRemove(event: React.MouseEvent<HTMLButtonElement>) {
    const { id } = event.currentTarget.dataset;

    if (!id) return;

    dispatch(CartThunks.removeProduct(id));
  }
};

export default Cart;
